import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { useCallback } from "react";

import ButtonWithPopout from "components/Buttons/ButtonWithPopout";
import StandardButton from "components/Buttons/StandardButton";
import PageSidebarPanel from "components/Entities/Page/PageSidebarPanel";

import SpreadTheWordLinks from "./SpreadTheWordLinks";

import { indexKey } from "utils/misc";
import sendGAEvent from "utils/sendGAEvent";

import useActivityContext from "hooks/useActivityContext";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  mobileSpreadTheWordPanel: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    paddingTop: "1.5rem",
    backgroundColor: "#FFF",
  },
  mobileButtonRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  mobileButtonLarge: {
    flex: 1,
  },
  mobileButtonSmall: {
    marginLeft: "1rem",
    width: "3rem",
  },
  mobileButtonSmallIcon: {
    fontSize: "1.5rem",
    lineHeight: ".5rem",
    overflow: "hidden",
    color: "#DFE1E6",
  },
  mobileClaimContainer: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "14rem",
  },
  topDivider: {
    marginTop: "1rem",
    paddingTop: "1rem",
    borderTop: "1px solid #F3F3F3",
  },
  bottomDivider: {
    marginBottom: "1rem",
    paddingBottom: "1rem",
    borderBottom: "1px solid #F3F3F3",
  },
  spreadTheWordTitle: {
    ...gStyles.avalonBold,
    color: colours.black,
    fontSize: "0.8125rem",
    textAlign: "center",
    marginBottom: "0.5rem",
  },
  spreadTheWordLinks: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  spreadTheWordLink: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: `1px solid ${colours.lightishGreyBorder}`,
    height: "2.75rem",
    width: "2.75rem",
    cursor: "pointer",
    fontSize: "1.3rem",
  },
};

const SpreadTheWordPanel = (props) => {
  const {
    bottomTitleDivider,
    eventProps,
    footerSections,
    headerContent,
    footerContent,
    hideShareButton,
    index,
    mobile,
    mobileShareButtonLabel,
    renderOnMount,
    shareAsButton,
    entity_type,
    entity,
    shareCustomStyles,
    sharingLinksInfo,
    sidebarStyles,
    title,
    topTitleDivider,
  } = props;
  const { styles } = useStyles(baseStyles, props);
  const activityContext = useActivityContext();

  const handleShareClick = useCallback(
    (toggleMenu, open) => () => {
      toggleMenu();

      if (!open) {
        sendGAEvent({
          entity_type,
          entity_id: entity?.get("id"),
          entity_name: entity?.get("title") || entity?.get("name"),
          context: "main share button",
          componentContext: "SpreadTheWordPanel",
          ...activityContext,
          action: "shareModalOpen",
        });
      }
    },
    [entity, entity_type, activityContext]
  );

  const renderMobileButton = useCallback(
    ({ toggleMenu, ref, open }) => (
      <StandardButton
        ref={ref}
        onClick={handleShareClick(toggleMenu, open)}
        variation="white"
        label={mobileShareButtonLabel}
        flat
        rounded
        customStyles={shareCustomStyles}
      />
    ),
    [mobileShareButtonLabel, shareCustomStyles, handleShareClick]
  );

  const renderMobileContent = useCallback(
    () => (
      <SpreadTheWordLinks
        sharingLinksInfo={sharingLinksInfo}
        eventProps={eventProps}
      />
    ),
    [eventProps, sharingLinksInfo]
  );

  const renderShareButton = () => (
    <ButtonWithPopout
      className={css(styles.mobileButtonRow, styles.mobileButtonLarge)}
      key="mobileButtonRow"
      renderButton={renderMobileButton}
      renderContent={renderMobileContent}
      positionFixed={mobile}
      placement="bottom"
      ariaLabel={mobileShareButtonLabel}
    />
  );

  const renderMobile = () => (
    <div className={css(styles.mobileSpreadTheWordPanel)}>
      {headerContent}
      {!hideShareButton && renderShareButton()}
      {footerContent}
      {footerSections}
    </div>
  );

  const renderShareSection = () => {
    if (shareAsButton) {
      return renderShareButton();
    }

    return (
      <div
        className={css(
          topTitleDivider && styles.topDivider,
          styles.bottomDivider
        )}
        key="headerContentDivider"
      >
        <div className={css(styles.spreadTheWordTitle)}>{title}</div>
        {sharingLinksInfo && (
          <SpreadTheWordLinks sharingLinksInfo={sharingLinksInfo} />
        )}
      </div>
    );
  };

  const renderDesktop = () => (
    <PageSidebarPanel
      styles={sidebarStyles}
      index={index}
      renderOnMount={renderOnMount}
    >
      {headerContent}
      {sharingLinksInfo && renderShareSection()}
      {footerContent}
      {footerSections &&
        footerSections.map((section, i) => (
          <div
            key={indexKey(`footerSections${i}`)}
            className={css(
              bottomTitleDivider &&
                i < footerSections.length - 1 &&
                styles.bottomDivider
            )}
          >
            {section}
          </div>
        ))}
    </PageSidebarPanel>
  );

  if (mobile) {
    return renderMobile();
  }

  return renderDesktop();
};

SpreadTheWordPanel.propTypes = {
  bottomTitleDivider: PropTypes.bool,
  eventProps: PropTypes.object,
  footerSections: PropTypes.node,
  headerButtons: PropTypes.array,
  headerContent: PropTypes.node,
  hideShareButton: PropTypes.bool,
  index: PropTypes.number,
  mobile: PropTypes.bool,
  mobileShareButtonLabel: PropTypes.string,
  renderOnMount: PropTypes.bool,
  shareAsButton: PropTypes.bool,
  shareCustomStyles: PropTypes.object,
  sharingLinksInfo: PropTypes.object,
  title: PropTypes.node,
  topTitleDivider: PropTypes.bool,
  entity: PropTypes.instanceOf(Map),
  entity_type: PropTypes.string,
};

SpreadTheWordPanel.defaultProps = {
  bottomTitleDivider: false,
  footerSections: null,
  headerButtons: null,
  headerContent: null,
  hideShareButton: false,
  index: 0,
  mobile: false,
  mobileShareButtonLabel: null,
  renderOnMount: false,
  shareAsButton: false,
  sharingLinksInfo: null,
  title: "Spread The Word",
  topTitleDivider: false,
};

export default SpreadTheWordPanel;
